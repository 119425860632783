var service = []

service["AX_ENTERPRISE_USER"] = "AX ENTERPRISE USER";
service["AX_SELF-SERVE_USER"] = "AX SELF-SERVE USER";
service["AX_SANDBOX_INSTANCE_TIER2"] = "AX_SANDBOX_INSTANCE_TIER2";
service["AX_TASK_USER"] = "AX_TASK_USER";
service["AAD_PREMIUM"] = "Azure Active Directory Premium P1";
service["RMS_S_ENTERPRISE"] = "Azure Active Directory Rights Management";
service["RIGHTSMANAGEMENT_ADHOC"] = "Azure Rights Management Services Ad-hoc";
service["CRMPLAN2"] = "Dynamics CRM Online Plan 2";
service["EMS"] = "Enterprise Mobility + Security E3";
service["EMSPREMIUM"] = "Enterprise Mobility + Security E5";
service["ENTERPRISEPACK_B_PILOT"] = "ENTERPRISEPACK_B_PILOT";
service["EXCHANGEENTERPRISE_FACULTY"] = "Exch Online Plan 2 for Faculty";
service["EXCHANGE_L_STANDARD"] = "Exchange Online (Plan 1)";
service["ATP_ENTERPRISE_FACULTY"] = "Exchange Online Advanced Threat Protection";
service["ATP_ENTERPRISE"] = "Exchange Online ATP";
service["EXCHANGESTANDARD"] = "Exchange Online Plan 1";
service["EXCHANGE_S_ENTERPRISE"] = "Exchange Online Plan 2 S";
service["EXCHANGEENTERPRISE"] = "Exchange Online Plan 2";
service["RIGHTSMANAGEMENT_STANDARD_FACULTY"] = "Information Rights Management for Faculty";
service["RIGHTSMANAGEMENT_STANDARD_STUDENT"] = "Information Rights Management for Students";
service["INTUNE_A_VL"] = "Intune (Volume License)";
service["MCOLITE"] = "Lync Online (Plan 1)";
service["CRMSTORAGE"] = "Microsoft Dynamics CRM Online Additional Storage";
service["FLOW_FREE"] = "Microsoft Flow Free";
service["IT_ACADEMY_AD"] = "Microsoft Imagine Academy";
service["POWERAPPS_INDIVIDUAL_USER"] = "Microsoft PowerApps and Logic flows";
service["STREAM"] = "Microsoft Stream";
service["MICROSOFT_BUSINESS_CENTER"] = "MICROSOFT_BUSINESS_CENTER";
service["MEE_FACULTY"] = "Minecraft Education Edition Faculty";
service["MEE_STUDENT"] = "Minecraft Education Edition Student";
service["STANDARDWOFFPACK_STUDENT"] = "O365 Education E1 for Students";
service["STANDARDWOFFPACK_IW_FACULTY"] = "O365 Education for Faculty";
service["STANDARDWOFFPACK_IW_STUDENT"] = "O365 Education for Students";
service["STANDARDPACK_STUDENT"] = "Office 365 (Plan A1) for Students";
service["ENTERPRISEPACKLRG"] = "Office 365 (Plan E3)";
service["EQUIVIO_ANALYTICS_FACULTY"] = "Office 365 Advanced Compliance for faculty";
service["STANDARDWOFFPACK_FACULTY"] = "Office 365 Education E1 for Faculty";
service["ENTERPRISEWITHSCAL_FACULTY"] = "Office 365 Education E4 for Faculty";
service["ENTERPRISEWITHSCAL_STUDENT"] = "Office 365 Education E4 for Students";
service["STANDARDPACK"] = "Office 365 Enterprise E1";
service["STANDARDWOFFPACK"] = "Office 365 Enterprise E2";
service["ENTERPRISEPACKWITHOUTPROPLUS"] = "Office 365 Enterprise E3 without ProPlus Add-on";
service["ENTERPRISEPACK"] = "Office 365 Enterprise E3";
service["ENTERPRISEWITHSCAL"] = "Office 365 Enterprise E4";
service["ENTERPRISEPREMIUM"] = "Office 365 Enterprise E5";
service["DESKLESSPACK_YAMMER"] = "Office 365 Enterprise K1 with Yammer";
service["DESKLESSPACK"] = "Office 365 Enterprise K1 without Yammer";
service["DESKLESSWOFFPACK"] = "Office 365 Enterprise K2";
service["MIDSIZEPACK"] = "Office 365 Midsize Business";
service["STANDARDWOFFPACKPACK_FACULTY"] = "Office 365 Plan A2 for Faculty";
service["STANDARDWOFFPACKPACK_STUDENT"] = "Office 365 Plan A2 for Students";
service["ENTERPRISEPACK_FACULTY"] = "Office 365 Plan A3 for Faculty";
service["ENTERPRISEPACK_STUDENT"] = "Office 365 Plan A3 for Students";
service["OFFICESUBSCRIPTION_FACULTY"] = "Office 365 ProPlus for Faculty";
service["LITEPACK_P2"] = "Office 365 Small Business Premium";
service["WACSHAREPOINTSTD"] = "Office Online STD";
service["SHAREPOINTWAC"] = "Office Online";
service["OFFICESUBSCRIPTION_STUDENT"] = "Office ProPlus Student Benefit";
service["OFFICE_PRO_PLUS_SUBSCRIPTION_SMBIZ"] = "Office ProPlus";
service["POWER_BI_INDIVIDUAL_USER"] = "Power BI for Office 365 Individual";
service["POWER_BI_STANDALONE"] = "Power BI for Office 365 Standalone";
service["POWER_BI_STANDARD"] = "Power BI for Office 365 Standard";
service["POWER_BI_PRO"] = "Power BI PRO";
service["PROJECTESSENTIALS"] = "Project Lite";
service["PROJECTONLINE_PLAN_1_FACULTY"] = "Project Online for Faculty Plan 1";
service["PROJECTONLINE_PLAN_2_FACULTY"] = "Project Online for Faculty Plan 2";
service["PROJECTONLINE_PLAN_1_STUDENT"] = "Project Online for Students Plan 1";
service["PROJECTONLINE_PLAN_2_STUDENT"] = "Project Online for Students Plan 2";
service["PROJECTPREMIUM"] = "Project Online Premium";
service["PROJECTPROFESSIONAL"] = "Project Online Professional";
service["PROJECTONLINE_PLAN_1"] = "Project Online with Project for Office 365";
service["PROJECTCLIENT"] = "Project Pro for Office 365";
service["PROJECT_MADEIRA_PREVIEW_IW_SKU"] = "PROJECT_MADEIRA_PREVIEW_IW";
service["SPE_E3"] = "Secure Productive Enterprise E3";
service["SHAREPOINTLITE"] = "SharePoint Online (Plan 1) Lite";
service["SHAREPOINTENTERPRISE_MIDMARKET"] = "SharePoint Online (Plan 1) MidMarket";
service["SHAREPOINTENTERPRISE"] = "SharePoint Online (Plan 2)";
service["SHAREPOINTSTANDARD"] = "SharePoint Online Plan 1";
service["STANDARD_B_PILOT"] = "STANDARD_B_PILOT";
service["STANDARDPACK_FACULTY"] = "STANDARDPACK_FACULTY";
service["VISIOCLIENT"] = "Visio Pro for Office 365";
service["YAMMER_ENTERPRISE"] = "Yammer Enterprise";
service["YAMMER_MIDSIZE"] = "Yammer Midsize"

export default service