export var EventType = {


    
    
    IconEdit: 400,
    ToogleMenu: 401,

  }
  
  Object.freeze(EventType)